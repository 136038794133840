<template>
    <span class="text-center">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
       <v-btn  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="dialog=!dialog" >
     {{ $store.getters.getTextMap().reorder_views }}<v-icon  right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-arrange-bring-to-front</v-icon>
     </v-btn>
     <v-dialog v-model="dialog" persistent max-width="600px">
   <v-card :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
     <v-card-title class="headline">{{ $store.getters.getTextMap().reorder_views }}</v-card-title>
     <v-card-text>
       <v-list v-model="selected" dense>
         <v-list-item
           v-for="(view, index) in views"
           :key="view.view_id"
         >
           <v-list-item-content>
             <v-list-item-title>{{ view.name }}</v-list-item-title>
           </v-list-item-content>
           <v-list-item-action>
               <div style="display: flex; justify-content: space-between;">
       <v-icon @click="moveUp(index)" :disabled="index === 0" color="blue">mdi-arrow-up-thin</v-icon>
       <v-icon @click="moveDown(index)" :disabled="index === views.length - 1" color="blue">mdi-arrow-down-thin</v-icon>
     </div>
   </v-list-item-action>
         </v-list-item>
       </v-list>
     </v-card-text>
     <v-card-actions>
       <v-spacer></v-spacer>
       <v-btn color="primary" @click="submit">{{ $store.getters.getTextMap().submit }}</v-btn>
       <v-btn color="error" @click="close">{{ $store.getters.getTextMap().cancel }}</v-btn>
     </v-card-actions>
   </v-card>
 </v-dialog>
 </span>
</template>

<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
export default {
    components:{
        InfoAlert
    },
 props: {
   views: {
     type: Array,
     default: () => []
   }
 },
 data() {
   return {
     dialog: false,
     selected: [],
     info:'',
     showDismissibleAlert:false,
   };
 },
 mounted(){
    this.views.sort((a, b) => a.seq - b.seq);
 },
 methods: {
   openDialog() {
     this.selected = this.views.map((view) => view.view_id);
     this.dialog = true;
   },
   moveUp(index) {
   if (index > 0) {
    //   console.log('Before move up:', this.views.map(view => view.seq));
     const temp = this.views[index];
     this.$set(this.views, index, this.views[index - 1]);
     this.$set(this.views, index - 1, temp);
    //   console.log('After move up:', this.views.map(view => view.seq));
   }
 },
 moveDown(index) {
   if (index < this.views.length - 1) {
    //  console.log('Before move down:', this.views.map(view => view.seq));
     const temp = this.views[index];
     this.$set(this.views, index, this.views[index + 1]);
     this.$set(this.views, index + 1, temp);
    //  console.log('After move down:', this.views.map(view => view.seq));
   }
 },

 submit() {
    // const updatedViews = this.views.map((view, index) => ({...view,seq: index + 1}));
    const updatedViews = this.views.map((view, index) => ({view_id:view.view_id,seq: index + 1}));
    //  console.log('Updated Views:', updatedViews); 
    axios.post(this.$store.state.api + "reorderView", {updateSequence:updatedViews}, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}}).then(response => {
        if (response.data.status == 'success') {
            this.loading = false;
            this.dialog = false;
            this.$store.dispatch('refreshViews')
            this.$emit('viewsupdated', updatedViews);
            
        } else {
            this.loading = false;
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
        }
    }).catch(err => {
        this.loading = false;
        this.info = err.message; 
        this.showDismissibleAlert = true;
    });
},

   close() {
     this.dialog = false;
   },
 },
};
</script>

 