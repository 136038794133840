<template>
<v-container >
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
        <div :style="[{'display':'flex','justify-content':'center', 'color':$store.getters.getColorPalette().accentCode}]">
          {{text}}
        </div>
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    name:'LoadingLinear',
    props:{
        text:{
            type:String,
            default:'Loading'
        }
    },

}
</script>