<template>
    <v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="viewForm"
            v-model="valid"          
        >
        <v-row>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select  :dark="$store.getters.getColorPalette().isDark" 
                        v-if="!loading"
          v-model="form.view_type"
          :items="viewTypeOptions"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().view_type"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
        </v-col>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                        :loading="loading"
                        v-model="form.msg"
                        :counter="55"
                        
                        :label="$store.getters.getTextMap().description"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                         :color="$store.getters.getColorPalette().submitbtnColor"
                       
                        @click="onSubmit"
                    >
                      {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                       :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
        </v-row>
    </v-form>
    </v-container>
            
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'CreateView',
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            form:{
                name:null,
                view_type:'tableView',
                data:{}
            },
            viewTypeOptions:[
                {'label':this.$store.getters.getTextMap().table_view, value:'tableView'},
                {'label':this.$store.getters.getTextMap().widget_view, value:'widgetView'},
                {'label':this.$store.getters.getTextMap().dynamic_timeframed_widget_view, value:'dynamicTimeframedWidgetView'},
                {'label':this.$store.getters.getTextMap().diagram_view, value:'sldView'},
                {'label':this.$store.getters.getTextMap().energy_usage_view, value:'energyUsageView'},
                {'label':this.$store.getters.getTextMap().machine_scroll_view, value:'machineScrollView'},
                {'label':this.$store.getters.getTextMap().param_scroll_view, value:'paramScrollView'},
                {'label':this.$store.getters.getTextMap().consolidated_view, value:'consolidatedView'},
                {'label':this.$store.getters.getTextMap().air_quality_view, value:'aqView'},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 55 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
        }
    },
    methods:{
        onSubmit(){
            this.$refs.viewForm.validate()
            if(this.valid){
                 this.loading=true
                let d=Object.assign({}, this.form)
                // console.log(d)
                d['data']={headers:[],params:[]}
                axios.post(this.$store.state.api+'createView',d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshViews')
                            this.$refs.viewForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>