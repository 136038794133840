<template>
  <v-container fluid >
    <span v-if="!viewsLoaded">
      <LoadingLinear text="Views are loading..." />
    </span>
    <span v-else>
      <v-row  no-gutters  class="py-0 my-0">
        <v-col align-self="center" cols="4" class="pt-6">
          <v-autocomplete
            v-model="viewName"
            :items="this.$store.state.views"
            :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            outlined
            dense
            multiple
            prepend-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search_view"
            item-text="name"
            item-value="value"
          ></v-autocomplete>
        </v-col>
        <v-spacer/>
        <v-col cols="auto" class="d-flex" align="right" align-self="center">
          <v-btn  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="clearFilters" >
          {{ $store.getters.getTextMap().clear_view_name_filter }}<v-icon  right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-close-circle-outline</v-icon>
      </v-btn>
        </v-col>
        <v-col v-if="$store.state.user && $store.state.settingMode" cols="auto" class="d-flex mx-1" align="right" align-self="center">
          <CreateReorderViewsModal :views="$store.state.views"/>
        </v-col>
        <v-col v-if="$store.state.user && $store.state.settingMode" cols="auto" class="d-flex" align="right" align-self="center">
          <CreateViewBottomSheet/>
        </v-col>
        <v-col v-if="$store.state.user && $store.state.settingMode" cols="auto" class="d-flex mr-1" align="right" align-self="center">
          <v-switch
            style="transform: scale(0.8);"
            inset
            color="primary"
            :dark="$store.getters.getColorPalette().isDark"
            v-model="autoChange"
            :label="$store.getters.getTextMap().view_auto_change"
          ></v-switch>
        </v-col>
      </v-row>
    <v-row no-gutters class="py-0 my-0">
      <v-col>
        <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
        <v-tabs
          :dark="$store.getters.getColorPalette().isDark"
          :background-color="$store.getters.getColorPalette().backgroundColorCode"
          v-model="tab"
          show-arrows
        >
          <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
          <v-tab
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().btnborderColorCode"
            v-for="view in sortedViews"
            :key="view.view_id"
          >
          <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>{{icons[view.view_type]}}</v-icon>{{view.name}}
          </v-tab>
        </v-tabs>
        <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider> 
        <v-tabs-items v-model="tab" :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
          <v-tab-item :dark="$store.getters.getColorPalette().isDark" v-for="view in sortedViews" :key="view.view_id">
            <component v-bind:is="viewHash[view.view_type]" :view="view"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row :dark="$store.getters.getColorPalette().isDark" v-if="$store.state.views.length==0">
      <v-col align="center">
        <v-icon :dark="$store.getters.getColorPalette().isDark" large>mdi-hail</v-icon><h3 :style="{'color':$store.getters.getColorPalette().accentCode ,'margin-top':'10px'}">{{ $store.getters.getTextMap().no_views_configured }}</h3>
      </v-col>
    </v-row>
  </span>
  </v-container>
</template>
<script>
import CreateViewBottomSheet from '@/components/modals/CreateViewBottomSheet'
import CreateReorderViewsModal from '@/components/modals/CreateReorderViewsModal'
import TableViewPage from '@/pages/views/TableViewPage'
import WidgetViewPage from '@/pages/views/WidgetViewPage'
import DynamicTimeframedWidgetView from '@/pages/views/DynamicTimeframedWidgetView'
import EnergyUsageViewPage from '@/pages/views/EnergyUsageViewPage'
import MachineScrollViewPage from '@/pages/views/MachineScrollViewPage'
import ParamScrollViewPage from '@/pages/views/ParamScrollViewPage'
import SLDViewPage from '@/pages/views/SLDViewPage'
import ConsolidatedViewPage from '@/pages/views/ConsolidatedViewPage'
import AQViewPage from '../pages/views/AQViewPage.vue'
import LoadingLinear from '@/components/display_components/LoadingLinear.vue'
export default {
  name:'EntureViews',
  components:{
    CreateViewBottomSheet,
    CreateReorderViewsModal,
    LoadingLinear
  },
  mounted() {
    document.title='Views'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }else{
      this.init()
      
    }
      // Check if there are saved views in local storage and use them
      // const savedViews = localStorage.getItem('views');
      // if (savedViews) {
      //   this.$store.commit('updateViews', JSON.parse(savedViews));
      // }
    },
    beforeDestroy() {
        this.autoChange=false;
    },
    data(){
      return {
        tab:null,
        autoChange:false,
        view_filter:0,
        viewName:[],
        viewList:[],
        viewHash:{
          'tableView':TableViewPage,
          'widgetView':WidgetViewPage,
          'dynamicTimeframedWidgetView':DynamicTimeframedWidgetView,
          'energyUsageView':EnergyUsageViewPage,
          'machineScrollView':MachineScrollViewPage,
          'paramScrollView':ParamScrollViewPage,
          'sldView':SLDViewPage,
          'consolidatedView':ConsolidatedViewPage,
          'aqView':AQViewPage,
        },
        icons:{
          "tableView":'mdi-table',
          "machineScrollView":'mdi-engine',
          "paramScrollView":'mdi-turbine',
          "sldView":'mdi-vector-line',
          "widgetView":'mdi-widgets-outline',
          "dynamicTimeframedWidgetView":'mdi-widgets-outline',
          "energyUsageView":'mdi-lightning-bolt',
          "consolidatedView":'mdi-consolidate',
          "aqView":'mdi-weather-windy',
        }
      }
    },
    computed:{
      viewsLoaded(){
        return this.$store.state.viewsLoaded
      },
      sortedViews() {
      // Sort views by seq in ascending order
        return this.viewList.slice().sort((a, b) => a.seq - b.seq);
      }
    },
  methods:{

    init(){
      this.viewList=this.$store.state.views

    },
    updateTab(){
      let len=this.viewList.length
      if(this.autoChange && len && !isNaN(this.tab)){
        this.tab=(++this.tab)%len
        setTimeout(this.updateTab, 20000)
      }
    },
    clearFilters(){
      this.viewList=this.$store.state.views
      this.viewName=[]
    },
    filterViews(){
      if(this.viewName && this.viewName.length>0){
        this.viewList=this.$store.state.views
        this.viewList = this.viewList.filter(x=>this.viewName.includes(x.name))
      }
    }
  },
  watch:{
    viewsLoaded(){
      this.init()
    },
    autoChange(){
        this.updateTab()
      },
      viewName(){
        this.filterViews()
      }
  }
}
</script>